import { lazy, Suspense } from 'react'

import { BaseAuthLayout } from 'core/layouts/BaseAuth'
import NoAuth from 'core/routes/guards/NoAuth'

const Login = lazy(() => import('./pages/login'))

export default [
  {
    path: '',
    element: <NoAuth component={BaseAuthLayout} />,
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<>...</>}>
            <Login />
          </Suspense>
        )
      }
    ]
  }
]
