import { lazy, Suspense } from 'react'
import Auth from 'core/routes/guards/Auth'
import NoAuth from 'core/routes/guards/NoAuth'

import { BaseLayout } from 'core/layouts/Base'

const Home = lazy(() => import('./pages/home'))
const NewBriefing = lazy(() => import('./pages/new-briefing'))
const Briefing = lazy(() => import('./pages/briefing'))

export default [
  {
    path: 'home',
    element: <NoAuth component={BaseLayout} />,
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<>...</>}>
            <Home />
          </Suspense>
        )
      }
    ]
  },
  {
    path: 'briefing',
    element: <NoAuth component={BaseLayout} />,
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<>...</>}>
            <Briefing />
          </Suspense>
        )
      }
    ]
  },
  {
    path: 'new-briefing',
    element: <NoAuth component={BaseLayout} />,
    children: [
      {
        path: '',
        element: (
          <Suspense fallback={<>...</>}>
            <NewBriefing />
          </Suspense>
        )
      }
    ]
  }
]
