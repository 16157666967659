import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 100vh;
  background-repeat: round;
`

export const ContainerOutlet = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: column;
    margin: 10px 10px;

    .gif-logo {
      height: 60%;
      width: 60%;
    }
  }
`

export const ContainerCard = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
`

export const ContainerNav = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 60px;

  @media ${({ theme }) => theme.device.tablet} {
    margin: 10px 10px;
  }

  .title {
    font-size: 26px;
    letter-spacing: 2px;
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: ${({ theme }) => theme.font.bold};
    font-family: ${({ theme }) => theme.font.familyAvenir};
  }

  .subtitle {
    font-weight: ${({ theme }) => theme.font.normal};
    color: ${({ theme }) => theme.colors.secondary};
  }
`

export const ContainerFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;
  .title {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: ${({ theme }) => theme.font.normal};
  }
`
