import { UserSession } from '../model/user-session'
import typeStorage from './types'

export function getSession(): UserSession {
  try {
    const session = window.localStorage.getItem(typeStorage.session)
    if (session) {
      return JSON.parse(session)
    }
    return {} as UserSession
  } catch (error) {
    return {} as UserSession
  }
}

export function getWorkspace() {
  const session = getSession()
  const workspaces = session.user?.WorkspaceUsers.filter(
    (item) => item.selected
  )
  return workspaces[0]?.Workspace?.id
}

export function getWorkspaceUser() {
  const session = getSession()
  const workspaces = session.user?.WorkspaceUsers.filter(
    (item) => item.selected
  )
  return workspaces[0]?.id
}
