import 'styled-components'

export default {
  grid: {
    container: '130rem',
    gutter: '3.2rem'
  },
  border: {
    radius: '11.25rem'
  },
  font: {
    family: 'Consolas, sans-serif',
    familyAvenir: 'Avenir, sans-serif',
    normal: 400,
    medium: 500,
    bold: 700,
    sizes: {
      xxsmall: '.8125rem',
      xsmall: '.875rem',
      small: '1rem',
      medium: '1.3rem',
      xmedium: '1.5rem',
      large: '2rem',
      xlarge: '3rem',
      xxlarge: '4rem',
      huge: '5.2rem'
    }
  },
  colors: {
    primary: '#181C32',
    secondary: '#292929',
    tertiary: '#161616',
    white: '#FFFFFF',
    light: '#E5E5E5',
    medium: '#a1a1a1',
    dark: '#181C32',
    shadow: '#E0E0E0',
    border: '#292929',
    inputActive: '#474761',
    mainBg: '#161616',
    red: '#F1416C',
    soft: '#C5D7FB'
  },
  spacings: {
    quark: '0.25rem',
    nano: '0.5rem',
    xxxs: '1rem',
    xxs: '1.5rem',
    xs: '2rem',
    sm: '2.5rem',
    md: '3rem',
    lg: '3.5rem',
    xl: '4rem',
    xxl: '5rem',
    xxxl: '7.5rem',
    huge: '10rem',
    giant: '12.5rem'
  },
  device: {
    mobileS: '(max-width: 320px)',
    mobileM: '(max-width: 375px)',
    mobileL: '(max-width: 425px)',
    tablet: '(max-width: 768px)',
    laptop: '(max-width: 1024px)',
    laptopL: '(max-width: 1440px)',
    desktop: '(max-width: 2560px)'
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50
  },
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out'
  }
} as const
