import { Outlet } from 'react-router-dom'

import Gif from 'core/assets/gifs/login.gif'
import * as Styled from './styles'

export function BaseAuthLayout() {
  return (
    <Styled.Container>
      <Styled.ContainerNav>
        <h1 className='title'>WAISYS</h1>
        <h3 className='subtitle'>unlocking the human future_</h3>
      </Styled.ContainerNav>

      <Styled.ContainerOutlet>
        <Outlet />

        <img src={Gif} alt='' className='gif-logo' />
      </Styled.ContainerOutlet>

      <Styled.ContainerFooter>
        <h1 className='title'>WAY GROUP ALL RIGHTS RESERVED ®</h1>
      </Styled.ContainerFooter>
    </Styled.Container>
  )
}
