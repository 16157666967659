import { css } from 'styled-components'

export default css`
  .p-xxxs {
    padding: ${({ theme }) => theme.spacings.xxxs};
  }

  .p-xxs {
    padding: ${({ theme }) => theme.spacings.xxs};
  }

  .p-xs {
    padding: ${({ theme }) => theme.spacings.xs};
  }

  .p-sm {
    padding: ${({ theme }) => theme.spacings.sm};
  }

  .p-md {
    padding: ${({ theme }) => theme.spacings.md};
  }

  .p-lg {
    padding: ${({ theme }) => theme.spacings.lg};
  }

  .p-xl {
    padding: ${({ theme }) => theme.spacings.xl};
  }

  .pb-xxxs {
    padding-bottom: ${({ theme }) => theme.spacings.xxxs};
  }

  .pb-xxs {
    padding-bottom: ${({ theme }) => theme.spacings.xxs};
  }

  .pb-xs {
    padding-bottom: ${({ theme }) => theme.spacings.xs};
  }

  .pb-sm {
    padding-bottom: ${({ theme }) => theme.spacings.sm};
  }

  .pb-md {
    padding-bottom: ${({ theme }) => theme.spacings.md};
  }

  .pb-lg {
    padding-bottom: ${({ theme }) => theme.spacings.lg};
  }

  .pb-xl {
    padding-bottom: ${({ theme }) => theme.spacings.xl};
  }

  .pr-xxxs {
    padding-right: ${({ theme }) => theme.spacings.xxxs};
  }

  .pr-xxs {
    padding-right: ${({ theme }) => theme.spacings.xxs};
  }

  .pr-xs {
    padding-right: ${({ theme }) => theme.spacings.xs};
  }

  .pr-sm {
    padding-right: ${({ theme }) => theme.spacings.sm};
  }

  .pr-md {
    padding-right: ${({ theme }) => theme.spacings.md};
  }

  .pr-lg {
    padding-right: ${({ theme }) => theme.spacings.lg};
  }

  .pr-xl {
    padding-right: ${({ theme }) => theme.spacings.xl};
  }

  .pl-xxxs {
    padding-left: ${({ theme }) => theme.spacings.xxxs};
  }

  .pl-xxs {
    padding-left: ${({ theme }) => theme.spacings.xxs};
  }

  .pl-xs {
    padding-left: ${({ theme }) => theme.spacings.xs};
  }

  .pl-sm {
    padding-left: ${({ theme }) => theme.spacings.sm};
  }

  .pl-md {
    padding-left: ${({ theme }) => theme.spacings.md};
  }

  .pl-lg {
    padding-left: ${({ theme }) => theme.spacings.lg};
  }

  .pl-xl {
    padding-left: ${({ theme }) => theme.spacings.xl};
  }

  .pt-xxxs {
    padding-top: ${({ theme }) => theme.spacings.xxxs};
  }

  .pt-xxs {
    padding-top: ${({ theme }) => theme.spacings.xxs};
  }

  .pt-xs {
    padding-top: ${({ theme }) => theme.spacings.xs};
  }

  .pt-sm {
    padding-top: ${({ theme }) => theme.spacings.sm};
  }

  .pt-md {
    padding-top: ${({ theme }) => theme.spacings.md};
  }

  .pt-lg {
    padding-top: ${({ theme }) => theme.spacings.lg};
  }

  .pt-xl {
    padding-top: ${({ theme }) => theme.spacings.xl};
  }

  .py-xxxs {
    padding: ${({ theme }) => theme.spacings.xxxs} 0;
  }

  .py-xxs {
    padding: ${({ theme }) => theme.spacings.xxs} 0;
  }

  .py-xs {
    padding: ${({ theme }) => theme.spacings.xs} 0;
  }

  .py-sm {
    padding: ${({ theme }) => theme.spacings.sm} 0;
  }

  .py-md {
    padding: ${({ theme }) => theme.spacings.md} 0;
  }

  .py-lg {
    padding: ${({ theme }) => theme.spacings.lg} 0;
  }

  .py-xl {
    padding: ${({ theme }) => theme.spacings.xl} 0;
  }

  .px-xxxs {
    padding: 0 ${({ theme }) => theme.spacings.xxxs};
  }

  .px-xxs {
    padding: 0 ${({ theme }) => theme.spacings.xxs};
  }

  .px-xs {
    padding: 0 ${({ theme }) => theme.spacings.xs};
  }

  .px-sm {
    padding: 0 ${({ theme }) => theme.spacings.sm};
  }

  .px-md {
    padding: 0 ${({ theme }) => theme.spacings.md};
  }

  .px-lg {
    padding: 0 ${({ theme }) => theme.spacings.lg};
  }

  .px-xl {
    padding: 0 ${({ theme }) => theme.spacings.xl};
  }
`
