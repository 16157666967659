import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 100vh;
  background-repeat: round;
`

export const ContainerCard = styled.div`
  width: 100%;
`

export const ContainerNav = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 60px;

  @media ${({ theme }) => theme.device.tablet} {
    margin: 10px 10px;
  }

  .title {
    font-size: 26px;
    letter-spacing: 2px;
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: ${({ theme }) => theme.font.bold};
    font-family: ${({ theme }) => theme.font.familyAvenir};
  }

  .subtitle {
    font-weight: ${({ theme }) => theme.font.normal};
  }

  .button {
    cursor: pointer;
  }

  .hr {
    width: 20px;
    height: 5px;
    background-color: ${({ theme }) => theme.colors.dark};
  }
`

export const ContainerFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;

  .title {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: ${({ theme }) => theme.font.normal};
  }
`
