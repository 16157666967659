import { Outlet, useNavigate } from 'react-router-dom'

import * as Styled from './styles'

export function BaseLayout() {
  const navigate = useNavigate()

  function handleOff() {
    navigate('/')
  }
  return (
    <Styled.Container>
      <Styled.ContainerNav>
        <h1 className='title'>WAISYS</h1>
        <div>
          <h3 className='subtitle mb-quark'>hi user_</h3>
          <h3 className='subtitle text-muted mb-quark'>Albert Einsten</h3>
          <hr className='mb-quark hr' />
          <h3 className='subtitle text-muted button' onClick={handleOff}>
            log off
          </h3>
        </div>
      </Styled.ContainerNav>

      <Styled.ContainerCard>
        <Outlet />
      </Styled.ContainerCard>

      <Styled.ContainerFooter>
        <h1 className='title'>WAY GROUP ALL RIGHTS RESERVED ®</h1>
      </Styled.ContainerFooter>
    </Styled.Container>
  )
}
